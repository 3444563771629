import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { GthUserModel } from '@sentinels/models';
import { Subscription } from 'rxjs';

import { GthAuthService } from '../../services/auth.service';
import { GthOnboardingService, GthOnboardingStep, GthOnboardingStepConfig } from '../../services/onboarding.service';

@Component({
  selector: 'gth-onboarding-step',
  templateUrl: './onboarding-step.component.html',
  styleUrls: ['./onboarding-step.component.scss'],
})
export class GthOnboardingStepComponent implements OnInit, OnChanges, OnDestroy {
  @Input()
  step?: GthOnboardingStep;

  @Output()
  dismiss = new EventEmitter<void>();

  configuration?: GthOnboardingStepConfig;

  get displayDismissButton() {
    return !this.step.startsWith('mock');
  }

  private user?: GthUserModel;
  private subscriptions = new Subscription();

  constructor(
    private onboarding: GthOnboardingService,
    private auth: GthAuthService,
  ) {
  }

  ngOnInit(): void {
    this.subscriptions.add(
      this.auth.userModel$.subscribe((user) => {
        this.user = user;
      }),
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.step) {
      if (!this.step) {
        this.configuration = undefined;
        return;
      }
      this.configuration = this.onboarding.getOnboardingStep(this.step);
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  async onActionButtonClick() {
    if (!this.step || !this.configuration) {
      return;
    }
    await this.configuration.action.action(this.user);
    await this.onDismiss();
  }

  async onDismissButtonClick() {
    if (!this.step) {
      return;
    }
    await this.onDismiss();
  }

  private async onDismiss() {
    await this.onboarding.dismiss(this.step);
    this.dismiss.emit();
  }
}
