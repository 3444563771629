import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '@sentinels/services/firebase/user.service';
import { first } from 'rxjs/operators';

import { GthUserModel } from '../../../../sentinels/src/lib/models/user';
import { GthAuthService } from './auth.service';
import { GthErrorLoggerService } from './cloud/error-logger.service';
import { ANALYTICS_CONSTS, GthAnalyticsService } from './logging/analytics.service';

export enum GthOnboardingStep {
  GamesSearch = 'games-search',
  TeamsSearch = 'teams-search',
  ParticipantsSearch = 'participants-search',
  MockGamesSearch = 'mock-games-search',
  MockTeamsSearch = 'mock-teams-search',
  MockParticipantsSearch = 'mock-participants-search',
  Messages = 'send-message',
  ViewYourProfile = 'view-your-profile',
  ViewPublicProfile = 'view-public-profile',
  UpdatePrivacy = 'update-user-privacy',
  UpdateAvailability = 'update-user-availability',
  UpdateDuprId = 'update-dupr-id',
  UpdatePayment = 'update-payment',
  ViewNotifications = 'view-notifications',
  MEH_LifetimeDeal = 'meh-lifetime-deal',
  AddToHomeScreen = 'add-to-home-screen',
}

export interface GthOnboardingStepConfig {
  title: string;
  description: string;
  action?: GthOnboardingStepConfigAnchor;
}

export interface GthOnboardingStepConfigAnchor {
  text: string;
  action: (user?: GthUserModel) => Promise<void> | void;
}

const CONTEXT = 'GthOnboardingService';

@Injectable({
  providedIn: 'root',
})
export class GthOnboardingService {
  constructor(
    private logger: GthErrorLoggerService,
    private analytics: GthAnalyticsService,
    private usersService: UserService,
    private auth: GthAuthService,
    private router: Router,
  ) { }

  async getRemainingSteps() {
    const user = await this.getUser();
    if (!user) {
      return undefined;
    }
    return user.onboardingSteps ?? [];
  }

  async hasCompletedStep(step: GthOnboardingStep) {
    const user = await this.getUser();
    if (!user) {
      return false;
    }

    const steps = user.onboardingSteps ?? [];
    return steps.includes(step);
  }

  async dismiss(step: GthOnboardingStep) {
    const user = await this.getUser();
    if (!user) {
      return false;
    }

    const steps = user.onboardingSteps ?? [];

    this.analytics.logEvent(ANALYTICS_CONSTS.onboardingDismissed, {
      step,
    });

    if (!steps.includes(step)) {
      steps.push(step);

      user.onboardingSteps = steps;

      const success = await this.usersService.updateUser(user);

      if (success) {
        this.log(`Completed onboarding step ${step}`);
      } else {
        this.log(`Error completing onboarding step ${step}`);
      }

      return success;
    }

    return false;
  }

  // eslint-disable-next-line max-len
  getOnboardingStep(step: GthOnboardingStep): GthOnboardingStepConfig {
    switch (step) {
      case GthOnboardingStep.GamesSearch:
        return {
          title: 'Searching for Available Games',
          // eslint-disable-next-line max-len
          description: `Explore thrilling sporting events near you, and if you don't find what you're looking for, be the catalyst by creating a new one to ignite the excitement!`,
          action: {
            text: 'Create a new event',
            action: () => {
              this.analytics.logEvent(ANALYTICS_CONSTS.onboardingOpened, {
                step,
              });
              this.router.navigate(['/discover/games/create']);
            },
          },
        };
      case GthOnboardingStep.MockGamesSearch:
        return {
          title: 'No Games in your Area',
          // eslint-disable-next-line max-len
          description: `There were no games in your area, but we have displayed some fake results to give you a feel of what Gametime Hero can offer.`,
        };
      case GthOnboardingStep.TeamsSearch:
        return {
          title: 'Searching for Teams',
          // eslint-disable-next-line max-len
          description: `Discover vibrant teams in your area or start your own if you can't find one – dive into local communities and unleash shared passions!`,
          action: {
            text: 'Create a new team',
            action: () => {
              this.analytics.logEvent(ANALYTICS_CONSTS.onboardingOpened, {
                step,
              });
              this.router.navigate(['/teams/create']);
            },
          },
        };
      case GthOnboardingStep.MockTeamsSearch:
        return {
          title: 'No Teams in your Area',
          // eslint-disable-next-line max-len
          description: `There were no teams in your area, but we have displayed some fake results to give you a feel of what Gametime Hero can offer.`,
        };
      case GthOnboardingStep.ParticipantsSearch:
        return {
          title: 'Searching for Available Participants',
          // eslint-disable-next-line max-len
          description: `Look around for participants in your area.  Whether you're a seasoned pro or just starting out, there's a place for you to connect, compete, and have a blast with fellow gamers who share your passion.  Also, don't forget to set your availability so fellow gamers are able to find you.`,
          action: {
            text: 'Set your availability',
            action: () => {
              this.analytics.logEvent(ANALYTICS_CONSTS.onboardingOpened, {
                step,
              });
              this.router.navigate(['/settings'], {
                queryParams: {
                  tab: 'availability',
                },
              });
            },
          },
        };
      case GthOnboardingStep.MockParticipantsSearch:
        return {
          title: 'No Participants in your Area',
          // eslint-disable-next-line max-len
          description: `There were no participants found in your area, but we have displayed some fake results to give you a feel of what Gametime Hero can offer.`,
        };
      case GthOnboardingStep.ViewYourProfile:
        return {
          title: 'Viewing your Private Profile',
          // eslint-disable-next-line max-len
          description: `From here you will be able to see games related to you as well as your badges. Don't forget to check out your Private profile to see how others see you.`,
          action: {
            text: 'View Public Profile',
            action: (user?: GthUserModel) => {
              if (!user) {
                return;
              }
              this.analytics.logEvent(ANALYTICS_CONSTS.onboardingOpened, {
                step,
              });
              this.router.navigate(['/profile', user.uid]);
            },
          },
        };
      case GthOnboardingStep.ViewPublicProfile:
        return {
          title: 'Viewing your Public Profile',
          // eslint-disable-next-line max-len
          description: `You're currently looking at your public profile; feel free to customize your privacy settings according to your preferences.`,
          action: {
            text: 'Setting your Privacy',
            action: () => {
              this.analytics.logEvent(ANALYTICS_CONSTS.onboardingOpened, {
                step,
              });
              this.router.navigate(['/settings'], {
                queryParams: {
                  tab: 'privacy',
                },
              });
            },
          },
        };
        case GthOnboardingStep.MEH_LifetimeDeal:
          return {
            title: 'Product Hunt Users - Lifetime Deal for 50 Dollars!',
            // eslint-disable-next-line max-len
            description: `Get Premium Access to My Event Hero for life - Only $50.`,
            action: {
              text: 'Get it now!',
              action: () => {
                this.analytics.logEvent(ANALYTICS_CONSTS.onboardingOpened, {
                  step,
                });
                this.router.navigate(['/settings'], {
                  queryParams: {
                    lifetime_code: 'EH450',
                  },
                });
              },
            },
          };
      case GthOnboardingStep.Messages:
      case GthOnboardingStep.UpdatePrivacy:
      case GthOnboardingStep.UpdateAvailability:
      case GthOnboardingStep.UpdateDuprId:
      case GthOnboardingStep.UpdatePayment:
      case GthOnboardingStep.ViewNotifications:
      default:
        return {
          title: 'Invalid',
          description: 'Invalid Onboarding Step',
        };
    }
  }

  private async getUser() {
    const user = await this.auth.userModel$
      .pipe(first())
      .toPromise();
    if (!user) {
      return undefined;
    }
    return user;
  }

  private log(text: string) {
    this.logger.debug(`${CONTEXT}: ${text}`);
  }
}
