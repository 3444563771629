// event.actions.ts

import { EventJoinerModel } from '@index/models/event-joiner';
import { createAction, props, union } from '@ngrx/store';
import { GthEventItemModel } from '@sentinels/models';

export enum ActionTypes {
    ProfileEventsInit = '[Profile Events] Init',
    ProfileEventsLoadOne = '[Profile Events] Load One',
    ProfileEventsLoadOneSuccess = '[Profile Events] Load One Success',
    ProfileEventsLoadOneError = '[Profile Events] Load One Error',

    ProfileEventsLoadListByUserId = '[Profile Events] Load List By User Id',
    ProfileEventsLoadListSuccess = '[Profile Events] Load List Success',
    ProfileEventsLoadListError = '[Profile Events] Load List Error',
}

export const eventsLoadOne = createAction(
    ActionTypes.ProfileEventsLoadOne,
    props<{ id: string }>(),
);
export const eventsLoadOneSuccess = createAction(
    ActionTypes.ProfileEventsLoadOneSuccess,
    props<{ event: GthEventItemModel }>(),
);
export const eventsLoadOneError = createAction(
    ActionTypes.ProfileEventsLoadOneError,
    props<{ error: unknown }>(),
);
export const eventsLoadListByUserId = createAction(
    ActionTypes.ProfileEventsLoadListByUserId,
    props<{ id: string }>(),
);
export const eventsLoadListSuccess = createAction(
    ActionTypes.ProfileEventsLoadListSuccess,
    props<{
        events: GthEventItemModel[],
        joiners: {[eventId: string]:EventJoinerModel[]}
    }>(),
);
export const eventsLoadListError = createAction(
    ActionTypes.ProfileEventsLoadListError,
    props<{ error: unknown }>(),
);


export const allProfileEvents = union({
    eventsLoadOne,
    eventsLoadOneSuccess,
    eventsLoadOneError,
    eventsLoadListByUserId,
    eventsLoadListSuccess,
    eventsLoadListError,
  });

export type ProfileEventsActionsUnion = typeof allProfileEvents;
